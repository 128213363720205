type StringDict = { [key: string]: string }

const borderColors: StringDict = {
  OKAY: '#878787',
  NORM: '#878787',
  ALARM: '#e35858',
  WARN: '#fcba1c'
}
const bgColors: StringDict = {
  OKAY: '#dadada',
  NORM: '#dadada',
  ALARM: '#ff9f9f',
  WARN: '#fcd883'
}

export const getFailureStateBorderColor = (failureState: string) => {
  if (failureState === undefined) {
    return borderColors.OKAY
  }

  const state = failureState.replaceAll('FAILURESTATE_', '').toUpperCase()

  return borderColors[state] ?? borderColors.OKAY
}

export const getFailureStateBackgroundColor = (failureState: string) => {
  if (failureState === undefined) {
    return bgColors.OKAY
  }
  const state = failureState.replaceAll('FAILURESTATE_', '').toUpperCase()

  return bgColors[state] ?? bgColors.OKAY
}
