import React, { useState, useCallback, useEffect } from 'react'
import Snackbar, { SnackbarCloseReason } from '@mui/joy/Snackbar'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useCaseContext } from '../Contexts'

interface SnackbarErrorProps {
  message?: string
  onClose?: () => void
}

export const SnackbarError: React.FC<SnackbarErrorProps> = ({
  message,
  onClose
}) => {
  const { isError: updateCaseError } = useCaseContext()
  const [snackbarOpen, setSnackbarOpen] = useState(
    !!updateCaseError || !!message
  )

  useEffect(() => {
    setSnackbarOpen(!!updateCaseError || !!message)
  }, [message, updateCaseError])

  const handleSnackbarClose = useCallback(
    (_: unknown, reason: SnackbarCloseReason) => {
      if (reason === 'clickaway') {
        return
      }
      onClose?.()
      setSnackbarOpen(false)
    },
    [onClose]
  )

  const handleCloseIconClick = useCallback(() => {
    setSnackbarOpen(false)
  }, [])

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={snackbarOpen}
      autoHideDuration={2500}
      onClose={handleSnackbarClose}
      key={'top-right'}
      sx={{ width: 'auto', maxWidth: 400, padding: 0, marginTop: '60px' }}
      color='danger'
      variant='plain'
    >
      <Alert
        severity='error'
        variant='standard'
        sx={{ width: '100%' }}
        action={
          <IconButton
            aria-label='close'
            color='inherit'
            size='small'
            onClick={handleCloseIconClick}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        }
      >
        {message ?? 'Error during updating'}
      </Alert>
    </Snackbar>
  )
}
