import React, { ReactNode } from 'react'
import './layout.css'
import { TopBar } from './TopBar'

type LayoutProps = {
  children: ReactNode
  signOut: () => void
  user: unknown
}
export const Layout: React.FC<LayoutProps> = ({ children, signOut }) => {
  return (
    <>
      <TopBar signOut={signOut} />
      <main className='main'>{children}</main>
    </>
  )
}
