import React, { FC } from 'react'
import './alarmCardActions.sass'
import {
  Failure,
  UpdatedFailureAcknowledgementData
} from '../../../../../Contexts/types'
import { AcknowledgmentSwitch } from './AcknowledgmentSwitch/acknowledgmentSwitch'

interface AlarmCardActionsProps {
  failure: Failure
  caseId: string
  updateFailureAcknowledgement: (
    acknowledgement: UpdatedFailureAcknowledgementData
  ) => void
}

export const AlarmCardActions: FC<AlarmCardActionsProps> = ({
  failure,
  caseId,
  updateFailureAcknowledgement
}) => {
  return (
    <div className='alarm-card-actions'>
      <AcknowledgmentSwitch
        caseId={caseId}
        failure={failure}
        updateFailureAcknowledgement={updateFailureAcknowledgement}
      />
    </div>
  )
}
