import React, { useEffect, useState } from 'react'
import { PumpImagePlaceholder } from './PumpImagePlaceholder/pumpImagePlaceholder'
import { PumpPictureModal } from './PumpPictureModal/pumpPictureModal'
import './pumpImage.sass'
import { useCaseContext } from '../../../Contexts'

export interface Props {
  pumpId: string | null
  organizationId: string | null
}

export const PumpImage = ({ pumpId, organizationId }: Props) => {
  const [isModalOpened, setModalOpened] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const { getPumpImage } = useCaseContext()

  useEffect(() => {
    async function loadImage(): Promise<void> {
      try {
        const url = await getPumpImage(pumpId, organizationId)

        setImageUrl(url)
      } catch (e) {}
    }
    if (!imageUrl || imageUrl === '') {
      loadImage()
    }
  }, [imageUrl, getPumpImage, pumpId, organizationId])

  if (!imageUrl || imageUrl === '') {
    return <PumpImagePlaceholder />
  }

  return (
    <div className='pump-image'>
      <img
        className='pump-image___img'
        onClick={() => setModalOpened(true)}
        src={imageUrl}
        alt={`pump_image`}
      />
      <PumpPictureModal
        imageUrl={imageUrl}
        isOpened={isModalOpened}
        onClose={() => setModalOpened(false)}
      />
    </div>
  )
}
