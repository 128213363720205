import * as React from 'react'
import { Grid, TextField, Typography } from '@mui/material'

type urlsListTextFieldProps = React.PropsWithChildren<{
  title: string
  value: string
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  helperTextValue?: string
}>

export const UrlsListTextField = (props: urlsListTextFieldProps) => {
  const { title, value, onChange, helperTextValue } = props
  const [valueError, setValueError] = React.useState(false)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    onChange(event)
    if (inputValue.trim() === '') {
      setValueError(true)
    } else {
      setValueError(false)
    }
  }

  React.useEffect(() => {
    setValueError(value.trim() === '')
  }, [value])

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
      </Grid>
      <Grid item xs={10}>
        <TextField
          required
          error={valueError}
          helperText={valueError ? `${helperTextValue} is required` : ''}
          autoFocus
          value={value}
          onChange={handleInputChange}
          rows={1}
          margin='normal'
          className='urls-list__input'
          variant='outlined'
          fullWidth
          sx={{
            margin: 0,
            padding: 0,
            backgroundColor: 'white',
            '&.MuiOutlinedInput-root': {
              backgroundColor: 'white'
            }
          }}
        />
      </Grid>
    </Grid>
  )
}
