export interface UsePumpInfoPanelFieldProps {
  handleCopy: (value: string | undefined) => Promise<void>
}

export const usePumpInfoPanelField = (): UsePumpInfoPanelFieldProps => {
  const handleCopy = async (value: string | undefined) => {
    if (value) {
      try {
        await navigator.clipboard.writeText(value)
      } catch (error) {
        console.error(error)
      }
    }
  }

  return {
    handleCopy
  }
}
