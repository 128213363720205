import { FC, useRef } from 'react'
import { Modal, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef
} from 'react-zoom-pan-pinch'

import './pumpPictureModal.sass'
import React from 'react'

export interface PumpPictureModalProps {
  className?: string
  imageUrl: string
  isOpened: boolean
  onClose: () => void
}

export const PumpPictureModal: FC<PumpPictureModalProps> = ({
  isOpened,
  onClose,
  imageUrl
}) => {
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null)

  const zoomToImage = () => {
    if (transformComponentRef.current) {
      const { zoomToElement } = transformComponentRef.current
      zoomToElement('pump-picture-modal__image')
    }
  }

  const createImageModalControls = (
    zoomIn: () => void,
    zoomOut: () => void,
    resetTransform: () => void
  ) => (
    <div className='pump-picture-modal__header'>
      <IconButton color='primary' size='large' onClick={() => zoomIn()}>
        <ZoomInIcon className='pump-picture-modal__zoomIn' />
      </IconButton>
      <IconButton color='primary' size='large' onClick={() => zoomOut()}>
        <ZoomOutIcon className='pump-picture-modal__zoomOut' />
      </IconButton>
      <IconButton color='primary' size='large' onClick={() => resetTransform()}>
        <SettingsBackupRestoreIcon className='pump-picture-modal__resetTransform' />
      </IconButton>
      <div className='pump-picture-modal__header__spacer' />
      <IconButton color='primary' size='large' onClick={() => onClose()}>
        <CloseIcon className='pump-picture-modal__close' />
      </IconButton>
    </div>
  )

  return (
    <Modal className='pump-picture-modal' open={isOpened} onClose={onClose}>
      <>
        <TransformWrapper>
          {(utils) => (
            <div className='pump-picture-modal__content'>
              {createImageModalControls(
                utils.zoomIn,
                utils.zoomOut,
                utils.resetTransform
              )}
              <TransformComponent>
                <img
                  src={imageUrl}
                  className='pump-picture-modal__image'
                  id='pump-picture-modal__image'
                  alt='Pump with sensor'
                  onClick={zoomToImage}
                />
              </TransformComponent>
            </div>
          )}
        </TransformWrapper>
      </>
    </Modal>
  )
}
