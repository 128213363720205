import { MRT_Cell, MRT_FilterOption } from 'material-react-table'
import { sortFailuresByState } from '../../utils/sortFailuresByState'
import { getFailureStateBackgroundColor } from '../../utils/getFailureStateColor'
import { formatFailureType } from '../../utils/formatFailureType'
import React from 'react'
import { Chip, MenuItem, Tooltip } from '@mui/material'
import { Failure } from '../../Contexts/types'
import { FilterVariant } from './table-classes/filterVariant'
import { CaseData } from './table-classes/caseData'
import { PumpData } from './table-classes/pumpData'
import { DetailsData } from './table-classes/detailsData'
import {
  createColumnsForAlarmByFailureType,
  createColumnsForFirstOccurrenceByFailureType,
  createColumnsForLastOccurrenceByFailureType,
  handleEmptyDateFunction
} from '../casesListFunctions'

const filterVariantTypes = new FilterVariant()

export const tableColumns = (
  columnVisibility: {
    [key: string]: boolean
  },
  distinctFailureTypes: string[]
) => [
  {
    header: 'Pump Id',
    accessorKey: 'pumpId'
  },
  {
    header: 'Pump',
    accessorKey: 'pumpData',
    Cell: ({ cell }: { cell: MRT_Cell<CaseData> }) => {
      const pumpData = cell.getValue() as PumpData

      return (
        <div>
          <div data-testid='case-table-organization'>
            {pumpData.organizationName}
          </div>
          <div data-testid='case-table-pump'>{pumpData.pumpName}</div>
        </div>
      )
    },
    maxSize: 160,
    minSize: 160
  },
  {
    header: 'Pump Name',
    accessorKey: 'pumpName'
  },
  {
    header: 'Type Series',
    accessorKey: 'pumpTypeSeries',
    maxSize: 150,
    minSize: 150
  },
  {
    header: 'Serial Number Pump',
    accessorKey: 'pumpSerialNumber'
  },
  {
    header: 'Location',
    accessorKey: 'pumpLocation'
  },
  {
    header: 'Technical Location',
    accessorKey: 'pumpTechnicalLocation'
  },
  {
    header: 'Manufacturer',
    accessorKey: 'pumpManufacturer',
    maxSize: 50,
    minSize: 50
  },
  {
    header: 'Serial Number Sensor',
    accessorKey: 'pumpSensorsSerialNumber_0'
  },
  {
    header: 'Details',
    accessorKey: 'detailsData',
    Cell: ({ cell }: { cell: MRT_Cell<CaseData> }) => {
      const detailsData = cell.getValue() as DetailsData

      return (
        <div>
          <div>{detailsData.pumpLocation}</div>
          <div>{detailsData.pumpTechnicalLocation}</div>
          <div>
            {detailsData.pumpSensorsSerialNumber_0} |{' '}
            {detailsData.pumpSerialNumber}
          </div>
        </div>
      )
    },
    maxSize: 260,
    minSize: 260
  },
  {
    header: 'Alarm',
    accessorKey: 'failures',
    maxSize: 220,

    Cell: ({ cell }: { cell: MRT_Cell<CaseData> }) => {
      const failures = cell.getValue() as Failure[]

      sortFailuresByState(failures)
      const children = failures.map((failure) => {
        const type = formatFailureType(failure.failureType)
        return (
          <Tooltip title={type ?? ''} arrow followCursor>
            <div
              className='table-entry-failure-status'
              style={{
                backgroundColor: `${getFailureStateBackgroundColor(failure.failureState)}`
              }}
            >
              {type}
            </div>
          </Tooltip>
        )
      })

      return <div> {children} </div>
    },
    filterFn: 'alarmFilter',
    renderColumnFilterModeMenuItems: ({
      onSelectFilterMode
    }: {
      onSelectFilterMode: (filterMode: MRT_FilterOption) => void
    }) => [
      <MenuItem
        key='alarmFilter'
        onClick={() => onSelectFilterMode('alarmFilter')}
      >
        Alarm Filter
      </MenuItem>,
      <MenuItem key='contains' onClick={() => onSelectFilterMode('contains')}>
        Contains
      </MenuItem>
    ],
    columnFilterModeOptions: ['alarmFilter', 'contains'],
    sortingFn: 'alarmSorting'
  },
  {
    header: 'Organization',
    accessorKey: 'organizationName'
  },
  {
    header: 'Tags',
    accessorKey: 'tags',
    Cell: ({ cell }: { cell: MRT_Cell<CaseData> }) => {
      const tags = cell.getValue() as string[]

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
          {tags.map((tag, index) => (
            <Chip
              label={tag.replace(/['"]+/g, '')}
              key={tag + index}
              data-testid={tag + index}
              style={{ margin: '2px' }}
            />
          ))}
        </div>
      )
    },
    filterFn: 'tagFilter',
    renderColumnFilterModeMenuItems: ({
      onSelectFilterMode
    }: {
      onSelectFilterMode: (filterMode: MRT_FilterOption) => void
    }) => [
      <MenuItem key='tagFilter' onClick={() => onSelectFilterMode('tagFilter')}>
        Include Tag Filter
      </MenuItem>,
      <MenuItem
        key='excludeTagFilter'
        onClick={() => onSelectFilterMode('excludeTagFilter')}
      >
        Exclude Tag Filter
      </MenuItem>,
      <MenuItem key='contains' onClick={() => onSelectFilterMode('contains')}>
        Contains
      </MenuItem>
    ],
    columnFilterModeOptions: ['tagFilter', 'excludeTagFilter', 'contains'],
    maxSize: 320,
    minSize: 320
  },
  {
    header: 'Guard Tags',
    accessorKey: 'guardTags',
    Cell: ({ cell }: { cell: MRT_Cell<CaseData> }) => {
      const guardTags = (cell.getValue() as string[]) ?? []

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
          {guardTags.map((tag, index) => (
            <Chip
              className='guardTagClass'
              label={tag.replace(/['"]+/g, '').toUpperCase()}
              key={tag + index}
              data-testid={tag + index}
              style={{ margin: '2px' }}
            />
          ))}
        </div>
      )
    },
    filterFn: 'tagFilter',
    renderColumnFilterModeMenuItems: ({
      onSelectFilterMode
    }: {
      onSelectFilterMode: (filterMode: MRT_FilterOption) => void
    }) => [
      <MenuItem key='tagFilter' onClick={() => onSelectFilterMode('tagFilter')}>
        Include Tag Filter
      </MenuItem>,
      <MenuItem
        key='excludeTagFilter'
        onClick={() => onSelectFilterMode('excludeTagFilter')}
      >
        Exclude Tag Filter
      </MenuItem>
    ],
    columnFilterModeOptions: ['excludeTagFilter', 'tagFilter'],
    maxSize: 320,
    minSize: 320
  },
  {
    header: 'Assignee',
    accessorKey: 'assignee',
    filterVariant: filterVariantTypes.autocomplete,
    enableClickToCopy: true,
    Cell: ({ cell }: { cell: MRT_Cell<CaseData> }) => {
      const assignee = cell.getValue() as string[]

      return <div data-testid={assignee}>{assignee}</div>
    }
  },
  {
    header: 'First Occurrence',
    accessorKey: 'firstAlarmOccurrence',
    accessorFn: (row: CaseData) => {
      return row.firstAlarmOccurrence ? new Date(row.firstAlarmOccurrence) : ''
    },
    Cell: ({ cell }: { cell: MRT_Cell<CaseData> }) => {
      const dateString = handleEmptyDateFunction(cell)
      return <div>{dateString}</div>
    },
    filterVariant: filterVariantTypes.datetime,
    filterFn: 'betweenInclusive',
    sortingFn: 'datetime'
  },
  {
    header: 'Last Occurrence',
    accessorKey: 'lastAlarmOccurrence',
    accessorFn: (row: CaseData) => {
      return row.lastAlarmOccurrence ? new Date(row.lastAlarmOccurrence) : ''
    },
    Cell: ({ cell }: { cell: MRT_Cell<CaseData> }) => {
      const dateString = handleEmptyDateFunction(cell)
      return <div>{dateString}</div>
    },
    filterVariant: filterVariantTypes.datetime,
    filterFn: 'betweenInclusive'
  },
  ...createColumnsForFirstOccurrenceByFailureType(distinctFailureTypes),
  ...createColumnsForLastOccurrenceByFailureType(distinctFailureTypes),
  ...createColumnsForAlarmByFailureType(distinctFailureTypes)
]
