import React from 'react'
import placeholder from '../../../../assets/images/pumpImage-placeholder.svg'
import './pumpImagePlaceholder.sass'

export const PumpImagePlaceholder = () => {
  return (
    <div className='pump-image-placeholder'>
      <img
        className='pump-image-placeholder___img'
        src={placeholder}
        alt={`pump_placeholder_image`}
      />
    </div>
  )
}
