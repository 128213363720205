import React, { memo, useCallback, useMemo } from 'react'
import { AppBar, Button, Toolbar, Typography } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import { ReactComponent as Logo } from './ksb-logo-834104de.svg'
import { useNavigate } from 'react-router-dom'
import { BackButton } from '../BackButton'

export const TopBar = memo(({ signOut }: { signOut: Function }) => {
  const handleClick = useCallback(() => {
    signOut()
  }, [signOut])
  const navigate = useNavigate()

  const handleLogoClick = () => {
    navigate('/')
  }

  const memoizedTopBar = useMemo(
    () => (
      <AppBar position='static'>
        <Toolbar>
          <BackButton />
          <Typography
            variant='h5'
            component='div'
            className='menu-title'
            sx={{ flexGrow: 1 }}
          >
            <Logo
              style={{
                width: 50,
                height: 50,
                cursor: 'pointer',
                marginTop: '-7px',
                marginRight: 20
              }}
              onClick={handleLogoClick}
            />
            <span style={{ cursor: 'pointer' }} onClick={handleLogoClick}>
              Issue Management
            </span>
          </Typography>
          <Button
            sx={{ color: '#00569d' }}
            onClick={handleClick}
            data-testid='logout-button'
          >
            <LogoutIcon />
          </Button>
        </Toolbar>
      </AppBar>
    ),
    [handleClick]
  )

  return memoizedTopBar
})
