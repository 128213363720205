import { Failure } from '../Contexts/types'

const alarmStateSortOrder = [
  'FAILURESTATE_ALARM',
  'FAILURESTATE_WARN',
  'FAILURESTATE_NORM',
  'FAILURESTATE_OKAY'
]

export function sortFailuresByState(failures: Failure[]): void {
  failures.sort((currentAlarm, nextAlarm) => {
    return (
      alarmStateSortOrder.indexOf(currentAlarm.failureState) -
      alarmStateSortOrder.indexOf(nextAlarm.failureState)
    )
  })
}
