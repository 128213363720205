import { useCaseContext } from '../../Contexts'
import { Case, CaseStatusEnum, Comment, History } from '../../Contexts/types'

export interface UseCaseDetailsContentHookProperties {
  assigneeHandler: (newAssignee: string | undefined) => Promise<any>
  sortedComments: Comment[]
  sortedHistoryEntries: History[]
}

export const useCaseDetailsContent = (
  activeCase: Case
): UseCaseDetailsContentHookProperties => {
  const { updateCase } = useCaseContext()

  const sortedComments = activeCase?.comments.sort(
    (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
  )

  const sortedHistoryEntries = activeCase?.history?.sort(
    (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
  )

  const assigneeHandler = async (newAssignee: string | undefined) => {
    if (!activeCase?.id) {
      throw Error('Invalid case id')
    }
    return updateCase({
      id: activeCase.id,
      title: activeCase.title,
      status:
        activeCase.status === CaseStatusEnum.CLOSED
          ? CaseStatusEnum.CLOSED
          : CaseStatusEnum.OPEN,
      description: activeCase.description,
      assignee: newAssignee ?? ''
    })
  }

  return {
    assigneeHandler,
    sortedComments,
    sortedHistoryEntries
  }
}
