import { CaseListEntry } from '../../types'

export const filterOutCasesByAcknowledgements = (
  cases: CaseListEntry[],
  acknowledgements: string[]
): CaseListEntry[] => {
  return cases.filter(
    (pump: CaseListEntry) =>
      !pump.failures.some(
        (failure) =>
          (failure.acknowledged ?? false) &&
          acknowledgements.includes(failure.failureType)
      )
  )
}
