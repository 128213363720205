import { Button, Grid, Tooltip } from '@mui/material'
import React from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import './PumpInfoPanelFields.sass'
import { usePumpInfoPanelField } from './usePumpInfoPanelField'

interface PumpInfoPanelFieldProps {
  name: string
  value: string | undefined
  label: string | undefined
  width: number
  // Keystone Interface: Feature can be removed once all properties can be shown.
  visible: boolean
}

export const PumpInfoPanelField: React.FC<PumpInfoPanelFieldProps> = (
  props
) => {
  const { name, value, label, width, visible } = props
  const { handleCopy } = usePumpInfoPanelField()

  // Keystone Interface: This check can be removed once all properties can be shown.
  if (!visible) {
    return null
  }

  return (
    <React.Fragment key={name}>
      <Grid container item xs={width}>
        <Grid item xs={11} className='pump-info-panel__info-field'>
          <Tooltip
            title={value ?? ''}
            arrow
            followCursor
            role={`PumpInfoPanelFieldTooltip__${name}`}
          >
            <div
              className='pump-info-panel__attributes_value'
              data-testid={`PumpInfoPanelFieldValue__${name}`}
            >
              {value ?? ''}
            </div>
          </Tooltip>
          <div
            className='pump-info-panel__attributes_name'
            data-testid={`PumpInfoPanelFieldLabel__${name}`}
          >
            {label}
          </div>
        </Grid>
        <Grid item xs={1} className='copy-button-container'>
          <Button
            variant='text'
            size='small'
            startIcon={<ContentCopyIcon />}
            onClick={() => handleCopy(value)}
            data-testid={`PumpInfoPanelCopyButton__${name}`}
            sx={{
              width: '24px',
              height: '24px',
              minWidth: '24px',
              padding: '4px',
              fontSize: '0.75rem',
              display: 'inline'
            }}
            className='copy-button'
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
