import * as React from 'react'
import { Button, DialogActions } from '@mui/material'

export type DialogActionsProps<T> = {
  buttons?: React.ReactElement
  onOk?: (data?: any) => void
  onClose?: Function
  onOkButtonTitle?: string
  okButtonVariant?: 'text' | 'outlined' | 'contained'
  onCloseButtonTitle?: string
  okCloseVariant?: 'text' | 'outlined' | 'contained'
}

export const IMDialogActions: React.FC<DialogActionsProps<null>> = (props) => {
  const {
    buttons,
    onClose,
    onOkButtonTitle,
    onCloseButtonTitle,
    okButtonVariant,
    okCloseVariant,
    onOk
  } = props

  const renderButtonsComponents = () => {
    return buttons
  }

  return (
    <DialogActions>
      {buttons ? (
        renderButtonsComponents()
      ) : (
        <React.Fragment>
          <Button
            data-testid='im-dialog-okay-button'
            type='submit'
            color={'primary'}
            variant={okButtonVariant ?? 'text'}
            onClick={onOk}
          >
            {onOkButtonTitle}
          </Button>
          <Button
            // @ts-ignore
            onClick={() => onClose()}
            color={'inherit'}
            variant={okCloseVariant ?? 'text'}
          >
            {onCloseButtonTitle}
          </Button>
        </React.Fragment>
      )}
    </DialogActions>
  )
}
