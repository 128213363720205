import { useEffect, useState } from 'react'

export interface Config {
  REGION: string
  USER_POOL_ID: string
  CLIENT_ID: string
  API_HOST_URL: string
  C4C_BASE_URL: string
  C4C_TICKET_ID_PARAM: string
  C4C_OPTIONAL_PARAM: string
}

const filename =
  process.env.NODE_ENV === 'production' ? 'config.json' : 'config.local.json'

export const useConfig = () => {
  const [config, setConfig] = useState<null | Config>(null)

  useEffect(() => {
    let cancelled = false

    ;(async () => {
      try {
        const response = await fetch(
          `${window.location.origin}/config/${filename}`,
          {
            credentials: 'include'
          }
        )

        const _config = await response.json()
        if (!cancelled) {
          setConfig(_config)
        }
      } catch (err) {
        console.error(err)
      }
    })()

    return () => {
      cancelled = true
    }
  }, [])

  return config
}
