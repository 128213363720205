type FilterVariantType =
  | 'select'
  | 'time'
  | 'text'
  | 'datetime'
  | 'autocomplete'
  | 'checkbox'
  | 'date'
  | 'date-range'
  | 'datetime-range'
  | 'multi-select'
  | 'range'
  | 'range-slider'
  | 'time-range'
  | undefined

export class FilterVariant {
  autocomplete: FilterVariantType = 'autocomplete'
  checkbox: FilterVariantType = 'checkbox'
  date: FilterVariantType = 'date'
  dateRange: FilterVariantType = 'date-range'
  datetime: FilterVariantType = 'datetime'
  datetimeRange: FilterVariantType = 'datetime-range'
  multiSelect: FilterVariantType = 'multi-select'
  range: FilterVariantType = 'range'
  rangeSlider: FilterVariantType = 'range-slider'
  select: FilterVariantType = 'select'
  text: FilterVariantType = 'text'
  time: FilterVariantType = 'time'
  timeRange: FilterVariantType = 'time-range'
}
