import React from 'react'
import './caseDetailsContentTitle.sass'
import { DebugBorder } from '../../../utils/debugBorder'

interface CaseDetailsContentTitleProps {
  title: string
}

export const CaseDetailsContentTitle: React.FC<CaseDetailsContentTitleProps> = (
  props
) => {
  return (
    <DebugBorder>
      <div className='case-details-content-title'>{props.title}</div>
    </DebugBorder>
  )
}
