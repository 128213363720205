import * as React from 'react'
import { DialogContent, Typography } from '@mui/material'

type IMDialogContentProps = React.PropsWithChildren<{ renderText?: string }>

export const IMDialogContent: React.FC<IMDialogContentProps> = (props) => {
  const { renderText } = props

  const renderCustom = () => {
    let text = renderText

    return <Typography component={'h4'}> {text} </Typography>
  }

  return (
    <DialogContent dividers>
      {props.children ? props.children : renderCustom()}
    </DialogContent>
  )
}
