import React from 'react'
import './casesList.sass'
import {
  MaterialReactTable,
  MRT_TableInstance,
  useMaterialReactTable
} from 'material-react-table'
import { IconButton, Link, Tooltip } from '@mui/material'
import { Info, SearchOffOutlined } from '@mui/icons-material'
import { CaseData } from './table-props/table-classes/caseData'
import { useCasesListStates } from './casesListHooks'
import {
  alarmFilterFunction,
  alarmSortingFunction,
  excludeTagFilterFunction,
  tagFilterFunction
} from './casesListFunctions' //TODO (Justice): Find a solution to make the structure of this factory method readable.

//TODO (Justice): Find a solution to make the structure of this factory method readable.
// I miss readability as seen in class-based approaches. I personally need more React Knowledge to solve this.
export const CasesList = () => {
  const {
    groupedColumnMode,
    setPagination,
    setSorting,
    setColumnVisibility,
    setColumnOrder,
    setColumnPinning,
    setGrouping,
    setColumnFilters,
    setColumnFilterFns,
    pagination,
    sorting,
    columnVisibility,
    columnOrder,
    columnPinning,
    grouping,
    columnFilters,
    columnFilterFns,
    resetTableFilters,
    columns,
    data
  } = useCasesListStates()

  const table: MRT_TableInstance<CaseData> = useMaterialReactTable({
    columns,
    data,
    enableGrouping: true,
    groupedColumnMode,
    enablePagination: true,
    enableColumnOrdering: true,
    enableColumnResizing: false,
    autoResetPageIndex: false,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onColumnPinningChange: setColumnPinning,
    onGroupingChange: setGrouping,
    onColumnFiltersChange: setColumnFilters,
    onColumnFilterFnsChange: setColumnFilterFns,
    state: {
      pagination,
      columnVisibility,
      sorting,
      columnPinning,
      columnOrder,
      grouping,
      columnFilters,
      columnFilterFns
    },
    initialState: {
      expanded: true,
      density: 'compact',
      showColumnFilters: true
    },
    localization: {
      filterTagFilter: 'Include Tag Filter (Tag1,Tag 2,Tag3 ...)',
      filterExcludeTagFilter: 'Exclude Tag Filter (Tag1,Tag 2,Tag3 ...)',
      filterAlarmFilter: 'Vibration, Batterystate, ...'
    } as any,
    enableDensityToggle: false,
    enableRowActions: true,
    enableColumnFilterModes: true,
    renderTopToolbarCustomActions: () => (
      <Tooltip title='Reset Table Properties'>
        <div className='reset-filters-button'>
          <IconButton onClick={resetTableFilters}>
            <SearchOffOutlined></SearchOffOutlined>
          </IconButton>
        </div>
      </Tooltip>
    ),
    renderRowActions: ({ row }) => {
      // navigate(caseDetailUrl)
      const caseId = row.original.pumpId
      const caseDetailUrl = `/cases/${caseId}`
      return (
        <Link href={caseDetailUrl}>
          <IconButton data-testid='case-details-button'>
            <Info />
          </IconButton>
        </Link>
      )
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '' //change header text
      }
    },
    enableGlobalFilter: false,
    enableColumnPinning: true,
    enableHiding: true,
    sortingFns: {
      alarmSorting: (oneRow, otherRow, columnId) =>
        alarmSortingFunction(oneRow, otherRow, columnId)
    },
    filterFns: {
      excludeTagFilter: (row, id, filterValue) =>
        excludeTagFilterFunction(row, id, filterValue),
      tagFilter: (row, id, filterValue) =>
        tagFilterFunction(row, id, filterValue),
      alarmFilter: (row, id, filterValue) =>
        alarmFilterFunction(row, id, filterValue)
    }
  })

  return (
    <div data-testid='case-list-table'>
      <MaterialReactTable table={table} />
    </div>
  )
}
