import { Grid } from '@mui/material'
import { Assignee } from '../../Assignee'
import React from 'react'
import './caseDetailsContent.sass'
import { CaseDetailsContentTitle } from './Title/caseDetailsContentTitle'
import { useCaseDetailsContent } from './useCaseDetailsContent'
import { Case } from '../../Contexts/types'
import { CaseDetailsContentDescription } from './Description/caseDetailsContentDescription'
import { AlarmCardsSection } from './AlarmCardSection/alarmCardsSection'
import { CommentsSection } from './Comments/commentsSection'
import { HistorySection } from './HistorySection/historySection'
import { CaseDetailsContentTags } from './Tags/caseDetailsContentTags'
import { CaseDetailsContentGuardTags } from './Tags/caseDetailsContentGuardTags'

interface CaseDetailsContentProps {
  activeCase: Case
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>
}

export const CaseDetailsContent: React.FC<CaseDetailsContentProps> = (
  props
) => {
  const { activeCase, setSnackbarMessage } = props
  const { assigneeHandler, sortedComments, sortedHistoryEntries } =
    useCaseDetailsContent(activeCase)

  return (
    <Grid container spacing={1}>
      <Grid item xs={9}>
        <CaseDetailsContentTitle title={activeCase.title} />
      </Grid>
      <Grid item xs={3}>
        <Assignee
          assignable
          assignee={activeCase.assignee}
          assigneeHandler={assigneeHandler}
        />
      </Grid>
      <Grid item xs={9}>
        <CaseDetailsContentTags
          activeCase={activeCase}
          setSnackbarMessage={setSnackbarMessage}
        />
      </Grid>
      <Grid item xs={3}>
        <CaseDetailsContentGuardTags activeCase={activeCase} />
      </Grid>
      <Grid item xs={12}>
        <CaseDetailsContentDescription
          activeCase={activeCase}
          setSnackbarMessage={setSnackbarMessage}
        />
      </Grid>
      <Grid item xs={12}>
        <AlarmCardsSection activeCase={activeCase} />
      </Grid>
      <Grid item xs={6}>
        <CommentsSection comments={sortedComments} />
      </Grid>
      <Grid item xs={6}>
        <HistorySection historyEntries={sortedHistoryEntries ?? []} />
      </Grid>
    </Grid>
  )
}
