import React from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import './backButton.sass'

interface BackButtonProps {
  to?: string
}

export const BackButton: React.FC<BackButtonProps> = React.memo(({ to }) => {
  const navigate = useNavigate()

  const handleBack = () => {
    if (to) {
      navigate(to)
    } else {
      navigate(-1)
    }
  }

  return (
    <div className='back-button' onClick={handleBack}>
      <ArrowBackIosNewIcon style={{ fontSize: 20, color: '#596780' }} />
    </div>
  )
})
