import React, { FC } from 'react'
import './alarmCardDetail.sass'
import { toStringWithPrecision } from '../../../../../utils/toStringWithPrecision'
import { formatDateToUtcString } from '../../../../../utils/formatDateString'
import { Failure } from '../../../../../Contexts/types'
import { AlarmCardDetail } from './alarmCardDetail'

const propertyLabels = {
  messageType: 'Message',
  alarmType: 'Type',
  countOfWarnings: 'Count',
  firstOccurrence: 'First Occurrence',
  lastOccurrence: 'Last Occurrence',
  value: 'Value measured',
  violatedThresholdValue: 'Threshold violated',
  autoFeedback: 'Autofeedback',
  status: 'Status'
}

interface AlarmCardDetailsProps {
  failure: Failure
}

export const AlarmCardDetails: FC<AlarmCardDetailsProps> = ({ failure }) => {
  return (
    <div className='alarm-card-details'>
      <AlarmCardDetail
        label={propertyLabels.value}
        value={toStringWithPrecision(failure.value, 4)}
      />
      <AlarmCardDetail
        label={propertyLabels.violatedThresholdValue}
        value={
          failure.violatedThresholdValue
            ? toStringWithPrecision(failure.violatedThresholdValue, 4)
            : ''
        }
      />
      <AlarmCardDetail
        label={propertyLabels.firstOccurrence}
        value={formatDateToUtcString(new Date(failure.firstOccurrence))}
      />
      <AlarmCardDetail
        label={propertyLabels.lastOccurrence}
        value={formatDateToUtcString(new Date(failure.lastOccurrence))}
      />
    </div>
  )
}
