import { PumpInfoPanel } from './pumpInfoPanel'
import { Grid } from '@mui/material'
import { UrlsList } from '../urlsList'
import React from 'react'
import { Case } from '../../Contexts/types'
import './pumpInfoPanelContainer.sass'

interface pumpInfoPanelContainerProps {
  activeCase: Case
}

export const PumpInfoPanelContainer: React.FC<pumpInfoPanelContainerProps> = (
  props: pumpInfoPanelContainerProps
) => {
  const { activeCase } = props

  return (
    <div>
      <div className='pump-info'>
        <PumpInfoPanel pump={activeCase} />
        <Grid item sm={12}>
          <UrlsList urls={activeCase.urls} />
        </Grid>
      </div>
    </div>
  )
}
