import React, { FC } from 'react'
import { Typography } from '@mui/material'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './alarmCardsSection.sass'
import { AlarmCard } from './AlarmCard/alarmCard'
import { Case } from '../../../Contexts/types'
import { sortFailuresByState } from '../../../utils/sortFailuresByState'
import { useCaseContext } from '../../../Contexts'
import { DebugBorder } from '../../../utils/debugBorder'

interface FailureSectionProps {
  activeCase: Case
}

const SLIDES_TO_SHOW = 3

export const AlarmCardsSection: FC<FailureSectionProps> = (props) => {
  const caseId = props.activeCase.id
  const failureList = props.activeCase.failures

  const { updateFailureAcknowledgement } = useCaseContext()
  sortFailuresByState(failureList)

  const contentListSize = failureList.length

  const sectionBody = !contentListSize ? (
    <Typography variant={'body1'}>
      There are no alarms in this case at the moment.
    </Typography>
  ) : (
    <div className='slider-container'>
      <Slider
        dots={false}
        autoplay={false}
        slidesToShow={SLIDES_TO_SHOW}
        slidesToScroll={2}
        infinite={false}
        touchMove={false}
        arrows={failureList.length > SLIDES_TO_SHOW}
      >
        {failureList.map((failure, index) => {
          return (
            <AlarmCard
              key={failure.failureType + index}
              failure={failure}
              caseId={caseId}
              updateFailureAcknowledgement={updateFailureAcknowledgement}
              acknowledged={failure.acknowledged}
            />
          )
        })}
      </Slider>
    </div>
  )

  return (
    <DebugBorder>
      <div className={'alarm-card-section'}>
        <div className='common-item-list--bold common-item-list__title'>
          <span className='case-details__relative-item'>Alarm Details</span>
        </div>
        {sectionBody}
      </div>
    </DebugBorder>
  )
}
