export const getLocalizedDateString = (
  dateString: string | null | undefined
) => {
  return dateString
    ? new Date(dateString).toLocaleString('de', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'UTC'
      })
    : ''
}

export const formatDateToUtcString = (date = new Date(), options = {}) => {
  return new Date(date).toLocaleString(undefined, {
    timeZone: 'UTC',
    ...options
  })
}
