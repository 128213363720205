export const formatFailureType = (failureType: string) => {
  return failureType
    .toLowerCase()
    .replaceAll('_', ' ')
    .split(' ')
    .map(_upperFirst)
    .join(' ')
}

const _upperFirst = (string: string) => {
  return string.slice(0, 1).toUpperCase() + string.slice(1, string.length)
}

export const formatFailureAccessorKey = (failureType: string) => {
  return `failure_${failureType.replaceAll('_', '').toLowerCase()}`
}

export const formatFailureState = (failureState: string) => {
  return failureState?.replaceAll('FAILURESTATE_', '').toUpperCase()
}
