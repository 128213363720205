import { TagsList } from '../../../TagsList'
import React from 'react'
import { Case } from '../../../Contexts/types'
import { DebugBorder } from '../../../utils/debugBorder'

export interface CaseDetailsContentGuardTagsProps {
  activeCase: Case
}

export const CaseDetailsContentGuardTags: React.FC<
  CaseDetailsContentGuardTagsProps
> = (props) => {
  const { activeCase } = props

  return (
    <DebugBorder>
      <TagsList
        title={'Guard Tags'}
        tags={activeCase.guardTags}
        removable={false}
        areTagsUpperCase={true}
      ></TagsList>
    </DebugBorder>
  )
}
