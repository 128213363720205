import { TextField } from '@mui/material'
import React from 'react'
import { FormButton } from '../../../FormButton'

type CommentFormProps = {
  onSubmit: () => void
  onCancel: () => void
  loading?: boolean
  value: string
  onChange: (val: string) => void
  disabledConfirm?: boolean
}

const iconButtonProps = { padding: 0, marginLeft: 1 }

export const CommentForm: React.FC<CommentFormProps> = ({
  onSubmit,
  onCancel,
  loading,
  value,
  onChange,
  disabledConfirm
}) => {
  const confirmDisabled = !value || loading || disabledConfirm

  return (
    <>
      <TextField
        autoFocus
        disabled={loading}
        value={value}
        onChange={(event) => onChange(event.currentTarget.value)}
        multiline
        rows={4}
        fullWidth
        margin='none'
      />
      <FormButton
        variant='accept'
        onClick={onSubmit}
        disabled={confirmDisabled}
        sx={iconButtonProps}
      />
      <FormButton
        variant='decline'
        onClick={onCancel}
        disabled={loading}
        sx={iconButtonProps}
      />
    </>
  )
}
