import { Box, Grid } from '@mui/material'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCaseContext } from '../../../Contexts'
import { useAuth } from '../../../Contexts/AuthContext'
import { CommentForm } from './commentForm'
import { SnackbarError } from '../../snackbarError'

type CommentFormProps = {
  isCommentFormOpen: boolean
  onClose: () => void
}

export const AddCommentForm: React.FC<CommentFormProps> = ({
  isCommentFormOpen,
  onClose
}) => {
  const { addComment } = useCaseContext()
  const { caseId } = useParams()
  const { userEmail } = useAuth()
  const [newComment, setNewComment] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const isDisabled = !newComment || !caseId || !userEmail || isLoading
  const [error, setError] = useState<string | null>(null)

  const handleSubmitComment = async () => {
    if (isDisabled) {
      return
    }
    setIsLoading(true)
    try {
      await addComment({
        author: userEmail,
        caseId: caseId,
        text: newComment
      })
      onClose()
      setNewComment('')
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message)
      } else {
        setError('Failed to add the comment')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleCancelAddComment = () => {
    onClose()
    setNewComment('')
  }

  return (
    <>
      {error && (
        <SnackbarError message={error} onClose={() => setError(null)} />
      )}
      <Grid item sm={12} className='common-item-list__title'>
        {isCommentFormOpen && (
          <Box sx={{ mb: 2.5 }}>
            <span className='common-item-list--bold common-item-list__author'>
              {userEmail}
            </span>
            <CommentForm
              onCancel={handleCancelAddComment}
              onChange={(value) => setNewComment(value)}
              value={newComment}
              onSubmit={handleSubmitComment}
              loading={isLoading}
            />
          </Box>
        )}
      </Grid>
    </>
  )
}
