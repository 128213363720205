import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  ToggleButton,
  Typography
} from '@mui/material'
import React, { useCallback, useState } from 'react'
import { SearchBar } from '../SearchBar'
import { CasesList } from '../CasesList'
import { Refresh } from '@mui/icons-material'
import { useCasesContext, useFetchCases } from '../Contexts/CasesContext'
import { FailureTypes } from '../Contexts/types'
import { formatFailureType } from '../utils/formatFailureType'

export const CasesOverview = () => {
  const {
    filteredCases,
    showClosedCases,
    showMutedCases,
    setShowClosedCases,
    setShowMutedCases,
    acknowledgements,
    setAcknowledgements
  } = useCasesContext()
  const [refreshError, setRefreshError] = useState<boolean>(false)

  const failureTypes = [
    FailureTypes.VIBRATION,
    FailureTypes.TEMPERATUREHIGH,
    FailureTypes.TEMPERATURELOW,
    FailureTypes.BATTERYSTATE,
    FailureTypes.OPERATINGHOURS_1STBEARINGLUBRICATION,
    FailureTypes.OPERATINGHOURS_BEARINGLUBRICATION,
    FailureTypes.OPERATINGHOURS_BEARING
  ]
  const { isLoading, isError, refetchCases } = useFetchCases()

  const handleRefresh = useCallback(async () => {
    setRefreshError(false)
    try {
      await refetchCases()
    } catch (error) {
      setRefreshError(true)
    }
  }, [refetchCases])

  const textToggleButton = (
    onChange: () => void,
    checked: boolean,
    testId: string,
    label: string
  ) => {
    return (
      <ToggleButton
        value={checked}
        onChange={onChange}
        data-testid={testId}
        selected={checked}
        style={{ width: '200px' }}
      >
        {label}
      </ToggleButton>
    )
  }

  const closedCasesToggle = () =>
    textToggleButton(
      () => setShowClosedCases((prevShowClosedCases) => !prevShowClosedCases),
      showClosedCases,
      'closed-cases-switch',
      showClosedCases ? 'Hide Closed' : 'Show Closed'
    )

  const mutedCasesToggle = () =>
    textToggleButton(
      () => setShowMutedCases((prevShowMutedCases) => !prevShowMutedCases),
      showMutedCases,
      'muted-cases-switch',
      showMutedCases ? 'Hide Muted' : 'Show Muted'
    )

  const handleAcknowledgementsChange = (event: SelectChangeEvent<string[]>) => {
    setAcknowledgements(event.target.value as string[])
  }

  const acknowledgementsSelect = (
    <FormControl sx={{ m: 1, width: 250 }}>
      <InputLabel id='acknowledgements-select-label'>
        Hide Acknowledgements
      </InputLabel>
      <Select
        labelId='acknowledgements-select-label'
        id='acknowledgements-select'
        multiple
        value={acknowledgements}
        onChange={handleAcknowledgementsChange}
        input={<OutlinedInput label='Acknowledgements Filter' />}
      >
        {failureTypes.map((failureType, index) => (
          <MenuItem key={failureType + index} value={failureType}>
            {formatFailureType(failureType)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  const topPart = (
    <Box>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid item container xs={6} alignItems='center' spacing={1}>
          <Grid item>{closedCasesToggle()}</Grid>
          <Grid item>{mutedCasesToggle()}</Grid>
          <Grid item>{acknowledgementsSelect}</Grid>
        </Grid>
        <Grid item container xs={6} alignItems='center' spacing={1}>
          <Grid item xs={2} textAlign={'right'}>
            <IconButton onClick={handleRefresh} data-testid='refresh-button'>
              <Refresh />
            </IconButton>
          </Grid>
          <Grid item xs={10}>
            <SearchBar />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )

  return (
    <>
      {topPart}
      {refreshError ? (
        <p>Error refreshing data</p>
      ) : isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress />
        </Box>
      ) : isError ? (
        <p>Error fetching data</p>
      ) : filteredCases.length > 0 ? (
        <CasesList />
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography
            gutterBottom
            variant='body1'
            component='div'
            className='slider-container__card-title'
          >
            No cases found
          </Typography>
        </Box>
      )}
    </>
  )
}
