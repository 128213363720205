import {
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  ListItem,
  Paper,
  Typography
} from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useCaseContext } from '../Contexts'
import { useLocation, useParams } from 'react-router-dom'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'

const TAGS_TO_IGNORE = ['muted']

export const TagsList = ({
  title,
  tags,
  flexEnd,
  children,
  removable,
  areTagsUpperCase
}: {
  title: string
  tags: string[]
  flexEnd?: boolean
  children?: React.ReactNode
  removable?: boolean
  areTagsUpperCase?: boolean
}) => {
  const { removeTagFromCase } = useCaseContext()
  const location = useLocation()
  const isRoot = location.pathname === '/'
  const { caseId } = useParams()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleTagRemoval = useCallback(
    async (tagToRemove: string) => {
      if (!caseId) return
      setLoading(true)

      try {
        await removeTagFromCase({ id: caseId, tag: tagToRemove.toLowerCase() })

        setError('')
      } catch (error) {
        setError((prevError) => {
          if (error instanceof TypeError) {
            return 'Network error occurred.'
          } else {
            return 'Error occurred while removing the tag.'
          }
        })
      } finally {
        setLoading(false)
      }
    },
    [caseId, removeTagFromCase]
  )

  return (
    <Grid
      item
      sm={12}
      className='common-item-list common-item-list__description '
    >
      <>
        <div
          className='common-item-list--bold common-item-list__title'
          style={{
            width: '100%'
          }}
        >
          <span className='case-details__relative-item'>{title}</span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'left',
            flexWrap: 'wrap',
            listStyle: 'none',
            width: '100%',
            gap: '10px'
          }}
        >
          {loading && <CircularProgress />}
          {error && (
            <Typography variant='body1' color='error'>
              {error}
            </Typography>
          )}

          {!error &&
            !loading &&
            tags.length > 0 &&
            tags.map((tag: string) => {
              if (TAGS_TO_IGNORE.includes(tag)) return <></>
              const upperCaseTag = tag.toUpperCase()
              if (removable) {
                return (
                  <Chip
                    key={tag}
                    label={areTagsUpperCase ? upperCaseTag : tag}
                    onDelete={() => handleTagRemoval(tag)}
                  />
                )
              } else {
                return (
                  <Chip
                    key={tag}
                    label={areTagsUpperCase ? upperCaseTag : tag}
                  />
                )
              }
            })}
          {children}
        </div>
      </>
    </Grid>
  )
}
