import React, { FC } from 'react'
import { IconButton, SxProps } from '@mui/material'
import { Cancel, CheckCircle } from '@mui/icons-material'

const iconsProps = {
  width: 15,
  height: 15
}

interface FormButtonProps {
  variant: 'accept' | 'decline'
  onClick?: () => void
  type?: 'submit' | 'button'
  sx?: SxProps
  disabled?: boolean
  className?: string
}

export const FormButton: FC<FormButtonProps> = ({
  variant,
  onClick,
  sx,
  disabled,
  className,
  type = 'button'
}) => {
  return (
    <IconButton
      type={type}
      className={className}
      onClick={disabled ? undefined : onClick}
      sx={{ cursor: disabled ? 'not-allowed' : 'pointer', ...sx }}
    >
      {variant === 'decline' ? (
        <Cancel sx={{ color: disabled ? 'grey' : 'red', ...iconsProps }} />
      ) : (
        <CheckCircle
          sx={{
            color: disabled ? 'grey' : 'green',
            ...iconsProps
          }}
        />
      )}
    </IconButton>
  )
}
