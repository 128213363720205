import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_ColumnOrderState,
  MRT_FilterOption,
  MRT_GroupingState
} from 'material-react-table'
import { useEffect, useMemo, useState } from 'react'
import { CaseData } from './table-props/table-classes/caseData'
import { useCasesContext } from '../Contexts/CasesContext'
import { tableColumns } from './table-props/tableColumns'
import { updateDateFiltersFunction } from './casesListFunctions'
import { FailureTypes } from '../Contexts/types'

export const useCasesListStates = () => {
  const { filteredCases, acknowledgements, setAcknowledgements } =
    useCasesContext()

  const failureTypes = filteredCases
    .map((c) => c.failures)
    .flatMap((x) => x)
    .map((x) => x.failureType)

  const distinctFailureTypes = failureTypes.filter(
    (item, index) => failureTypes.indexOf(item) === index
  )

  const [columnVisibility, setColumnVisibility] = useState<{
    [key: string]: boolean
  }>(initialColumnVisibility)

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100
  })

  const [sorting, setSorting] = useState([
    {
      id: 'lastAlarmOccurrence',
      desc: true
    }
  ])

  const [columnPinning, setColumnPinning] = useState<{
    left?: string[] | undefined
  }>({
    left: ['mrt-row-actions']
  })

  const [grouping, setGrouping] = useState<MRT_GroupingState>([])

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([
    {
      id: 'guardTags',
      value: 'test / demo, sensor defect'
    },
    {
      id: 'firstAlarmOccurrence',
      value: ['', '']
    },
    { id: 'lastAlarmOccurrence', value: ['', ''] },
    {
      id: 'firstOccurrence_vibration',
      value: ['', '']
    },
    { id: 'lastOccurrence_vibration', value: ['', ''] },
    {
      id: 'firstOccurrence_temperaturehigh',
      value: ['', '']
    },
    { id: 'lastOccurrence_temperaturehigh', value: ['', ''] },
    {
      id: 'firstOccurrence_nodata',
      value: ['', '']
    },
    { id: 'lastOccurrence_nodata', value: ['', ''] },
    {
      id: 'firstOccurrence_xyz',
      value: ['', '']
    },
    { id: 'lastOccurrence_xyz', value: ['', ''] },
    {
      id: 'firstOccurrence_other',
      value: ['', '']
    },
    { id: 'lastOccurrence_other', value: ['', ''] }
  ])

  const columns: MRT_ColumnDef<CaseData, any>[] = useMemo(
    () => tableColumns(columnVisibility, distinctFailureTypes),
    [columnVisibility, distinctFailureTypes]
  )

  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>([
    'mrt-row-select',
    ...columns.map((c) => c.accessorKey || '')
  ])
  const defaultColumnFilterFns = Object.fromEntries(
    columns.map((column) => {
      if (column.accessorKey === 'failures') {
        return [column.accessorKey, 'alarmFilter']
      } else if (column.accessorKey === 'tags') {
        return [column.accessorKey, 'tagFilter']
      } else if (column.accessorKey === 'guardTags') {
        return [column.accessorKey, 'excludeTagFilter']
      } else if (
        column.accessorKey === 'firstAlarmOccurrence' ||
        column.accessorKey === 'lastAlarmOccurrence'
      ) {
        return [column.accessorKey, 'betweenInclusive']
      } else if (column.accessorKey?.startsWith('firstOccurrence_')) {
        const eventType = column.accessorKey.substring(
          'firstOccurrence_'.length
        )
        const betweenInclusiveTypes = [
          'vibration',
          'temperaturehigh',
          'nodata',
          'xyz',
          'other'
        ]
        if (betweenInclusiveTypes.includes(eventType)) {
          return [column.accessorKey, 'betweenInclusive']
        } else {
          return [column.accessorKey, 'fuzzy']
        }
      } else if (column.accessorKey?.startsWith('lastOccurrence_')) {
        const eventType = column.accessorKey.substring('lastOccurrence_'.length)
        const betweenInclusiveTypes = [
          'vibration',
          'temperaturehigh',
          'nodata',
          'xyz',
          'other'
        ]
        if (betweenInclusiveTypes.includes(eventType)) {
          return [column.accessorKey, 'betweenInclusive']
        } else {
          return [column.accessorKey, 'fuzzy']
        }
      } else {
        return [column.accessorKey, 'fuzzy']
      }
    })
  )
  const [columnFilterFns, setColumnFilterFns] = useState<
    Record<string, MRT_FilterOption>
  >(defaultColumnFilterFns)
  const data: CaseData[] = useMemo(
    () =>
      filteredCases
        .sort(
          (a, b) =>
            new Date(b.created).getTime() - new Date(a.created).getTime()
        )
        ?.map((caseListEntry) => {
          let caseData: CaseData = {
            pumpId: caseListEntry.id,
            pumpName: caseListEntry.pumpName ?? '',
            pumpTypeSeries: caseListEntry.pumpTypeSeries ?? '',
            pumpSerialNumber: caseListEntry.pumpSerialNumber ?? '',
            pumpLocation: caseListEntry.pumpLocation ?? '',
            pumpTechnicalLocation: caseListEntry.pumpTechnicalLocation ?? '',
            pumpManufacturer: caseListEntry.pumpManufacturer ?? '',
            pumpSensorsSerialNumber_0:
              caseListEntry.pumpSensorsSerialNumber_0 ?? '',
            organizationName: caseListEntry.organizationName ?? '',
            detailsData: {
              pumpSerialNumber: caseListEntry.pumpSerialNumber ?? '',
              pumpSensorsSerialNumber_0:
                caseListEntry.pumpSensorsSerialNumber_0 ?? '',
              pumpTechnicalLocation: caseListEntry.pumpTechnicalLocation ?? '',
              pumpLocation: caseListEntry.pumpLocation ?? ''
            },
            pumpData: {
              pumpName: caseListEntry.pumpName ?? '',
              organizationName: caseListEntry.organizationName ?? ''
            },
            tags: caseListEntry.tags ?? '',
            guardTags: caseListEntry.guardTags ?? '',
            failures: caseListEntry.failures ?? '',
            status: caseListEntry.status ?? '',
            assignee: caseListEntry.assignee ?? '',
            firstAlarmOccurrence: caseListEntry.firstAlarmOccurrence ?? '',
            lastAlarmOccurrence: caseListEntry.lastAlarmOccurrence ?? ''
          }
          caseListEntry.failures.forEach((failure) => {
            ;(caseData as any)[
              `firstOccurrence_${failure.failureType.replaceAll('_', '').toLowerCase()}`
            ] = failure.firstOccurrence ?? ''
            ;(caseData as any)[
              `lastOccurrence_${failure.failureType.replaceAll('_', '').toLowerCase()}`
            ] = failure.lastOccurrence ?? ''
            ;(caseData as any)[
              `failure_${failure.failureType.replaceAll('_', '').toLowerCase()}`
            ] = failure ?? ''
          })
          return caseData
        }),
    [filteredCases]
  )

  const [groupedColumnMode] = useState<false | 'remove' | 'reorder'>('reorder')

  const [isTableInitialized, setIsTableInitialized] = useState<boolean>(false)
  useEffect(function initializeTableFromLocalStorage() {
    const savedState = localStorage.getItem('tableState')
    if (savedState) {
      const parsedState = JSON.parse(savedState)
      const setStateFromParsedState = () => {
        setPagination(parsedState.pagination || { pageIndex: 0, pageSize: 100 })
        setColumnVisibility(parsedState.columnVisibility || {})
        setSorting(parsedState.sorting || [])
        setColumnPinning(parsedState.columnPinning || {})
        setColumnOrder(parsedState.columnOrder || [])
        setGrouping(parsedState.grouping || [])
        const updatedFilters = parsedState.columnFilters.map(
          updateDateFiltersFunction
        )
        setColumnFilters(updatedFilters || [])
        setColumnFilterFns(parsedState.columnFilterFns || '')
        setIsTableInitialized(true)
        setAcknowledgements(
          parsedState.acknowledgements || [FailureTypes.VIBRATION]
        )
      }

      setStateFromParsedState()
    } else {
      setIsTableInitialized(true)
    }
  }, [])

  useEffect(
    function saveTableDataToLocalStorage() {
      if (!isTableInitialized) {
        return
      }

      const tableState = {
        pagination,
        columnVisibility,
        sorting,
        columnPinning,
        columnOrder,
        grouping,
        columnFilters,
        columnFilterFns,
        acknowledgements
      }

      localStorage.setItem('tableState', JSON.stringify(tableState))
    },
    [
      pagination,
      columnVisibility,
      sorting,
      columnPinning,
      columnOrder,
      grouping,
      columnFilters,
      columnFilterFns,
      isTableInitialized,
      acknowledgements,
      setAcknowledgements
    ]
  )
  const resetTableFilters = () => {
    setColumnVisibility(initialColumnVisibility)

    setPagination({
      pageIndex: 0,
      pageSize: 100
    })

    setSorting([
      {
        id: 'lastAlarmOccurrence',
        desc: true
      }
    ])

    setColumnPinning({
      left: ['mrt-row-actions']
    })

    setGrouping([])

    setColumnFilters([
      {
        id: 'guardTags',
        value: 'test / demo, sensor defect'
      },
      {
        id: 'firstAlarmOccurrence',
        value: ['', '']
      },
      { id: 'lastAlarmOccurrence', value: ['', ''] },
      {
        id: 'firstOccurrence_vibration',
        value: ['', '']
      },
      { id: 'lastOccurrence_vibration', value: ['', ''] },
      {
        id: 'firstOccurrence_temperaturehigh',
        value: ['', '']
      },
      { id: 'lastOccurrence_temperaturehigh', value: ['', ''] },
      {
        id: 'firstOccurrence_nodata',
        value: ['', '']
      },
      { id: 'lastOccurrence_nodata', value: ['', ''] },
      {
        id: 'firstOccurrence_xyz',
        value: ['', '']
      },
      { id: 'lastOccurrence_xyz', value: ['', ''] },
      {
        id: 'firstOccurrence_other',
        value: ['', '']
      },
      { id: 'lastOccurrence_other', value: ['', ''] }
    ])

    setColumnOrder([
      'mrt-row-select',
      ...columns.map((c) => c.accessorKey || '')
    ])
    setColumnFilterFns(defaultColumnFilterFns)

    setAcknowledgements([FailureTypes.VIBRATION])
  }
  return {
    groupedColumnMode,
    setPagination,
    setSorting,
    setColumnVisibility,
    setColumnOrder,
    setColumnPinning,
    setGrouping,
    setColumnFilters,
    setColumnFilterFns,
    pagination,
    sorting,
    columnVisibility,
    columnOrder,
    columnPinning,
    grouping,
    columnFilters,
    columnFilterFns,
    resetTableFilters,
    columns,
    data,
    failureTypes,
    distinctFailureTypes,
    acknowledgements,
    setAcknowledgements
  }
}

const initialColumnVisibility = {
  pumpId: false,
  pumpData: true,
  pumpName: true,
  pumpManufacturer: true,
  pumpSerialNumber: false,
  detailsData: true,
  failures: true,
  tags: true,
  assignee: true,
  pumpSensorsSerialNumber_0: false,
  pumpLocation: false,
  pumpTechnicalLocation: false,
  organizationName: false,
  manufacturerPump: false,
  pumpTypeSeries: false,
  guardTags: true,
  firstAlarmOccurrence: true,
  firstOccurrence_vibration: true,
  firstOccurrence_temperaturehigh: false,
  firstOccurrence_temperaturelow: false,
  firstOccurrence_batterystate: false,
  firstOccurrence_batteryvoltage: false,
  firstOccurrence_operatinghoursbearing: false,
  firstOccurrence_nodata: false,
  firstOccurrence_xyz: false,
  firstOccurrence_other: false,
  lastAlarmOccurrence: true,
  lastOccurrence_vibration: true,
  lastOccurrence_temperaturehigh: false,
  lastOccurrence_temperaturelow: false,
  lastOccurrence_batterystate: false,
  lastOccurrence_batteryvoltage: false,
  lastOccurrence_operatinghoursbearing: false,
  lastOccurrence_nodata: false,
  lastOccurrence_xyz: false,
  lastOccurrence_other: false,
  failure_vibration: false,
  failure_temperaturehigh: false,
  failure_batterystate: false,
  failure_batteryvoltage: false,
  failure_operatinghoursbearing: false,
  failure_nodata: false,
  failure_xyz: false,
  failure_other: false
}
