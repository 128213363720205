import React from 'react'
import IconButton from '@mui/material/IconButton'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import { TextField } from '@mui/material'
import { FormButton } from '../../../FormButton'
import { iconsProps } from '../../caseDetails'
import { Case } from '../../../Contexts/types'
import { useCaseDetailsContentDescription } from './useCaseDetailsContentDescription'
import { DebugBorder } from '../../../utils/debugBorder'
import './caseDetailsContentDescription.sass'

export interface CaseDetailsContentDescriptionProps {
  activeCase: Case
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>
}

export const CaseDetailsContentDescription: React.FC<
  CaseDetailsContentDescriptionProps
> = (props) => {
  const { activeCase } = props
  const {
    editMode,
    editDescription,
    handleCancel,
    handleFieldChange,
    handleSaveDescription,
    handleEditClick,
    isSaveDescriptionDisabled
  } = useCaseDetailsContentDescription(props)

  const multilineText = activeCase.description
    ? activeCase.description
        .trim()
        .split('\n')
        .map((t) => {
          return <p className='case-details-content-description-read'>{t}</p>
        })
    : ''

  return (
    <DebugBorder>
      <div className='case-details-content-description-label'>
        <span className=''>
          Description
          {!editMode && (
            <IconButton className='' onClick={handleEditClick}>
              <ModeEditIcon sx={{ color: '#0080f0', ...iconsProps }} />
            </IconButton>
          )}
        </span>
      </div>
      {!editMode ? (
        <div>
          <p className='case-details-content-description-read'>
            {multilineText}
          </p>
        </div>
      ) : (
        <div>
          <TextField
            autoFocus
            value={editDescription}
            onChange={handleFieldChange}
            multiline
            rows={4}
            fullWidth
            margin='normal'
          />
          <FormButton
            disabled={isSaveDescriptionDisabled()}
            onClick={handleSaveDescription}
            variant='accept'
          />
          <FormButton onClick={handleCancel} variant='decline' />
        </div>
      )}
    </DebugBorder>
  )
}
