import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { Grid, TextField, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import React, { KeyboardEvent, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCaseContext } from '../Contexts'

import { FormButton } from '../FormButton'
import { SnackbarError } from './snackbarError'
import './urlsList.sass'
import { IMDialog } from '../Dialog/dialog'
import { UrlsListTextField } from './urlsListTextField'
import { useConfig } from '../hooks/useConfig'
import { Url } from '../Contexts/types'

const iconsProps = {
  width: 15,
  height: 15
}

const validateUrl = (url: string) => {
  const urlRegex =
    /^(?:(?:https?|ftp):\/\/)?(?:\w+\.)+\w{2,6}(?:\/\S*)?(?:\?(?:[\w\d+%&=]+(?:$|&))*$)?/

  return urlRegex.test(url)
}

export const UrlsList = React.memo(
  ({ urls }: { urls: Url[] }): JSX.Element | null => {
    const { postUrl, removeUrl } = useCaseContext()
    const [error, setError] = useState<string | null>(null)

    const [urlAddingMode, setUrlAddingMode] = useState<boolean | 'loading'>(
      false
    )
    const [c4cUrlAddingMode, setC4cUrlAddingMode] = useState<
      boolean | 'loading'
    >(false)
    const [showDialog, setShowDialog] = useState<boolean>(false)
    const [userID, setUserID] = useState('')
    const [ticketID, setTicketID] = useState('')
    const [urlDescription, setUrlDescription] = useState('')
    const [urlState, setUrlState] = useState({ url: '', text: '' })
    const { caseId } = useParams()
    const handleCancelClick = () => {
      setUrlAddingMode(false)
      setUrlState({ url: '', text: '' })
    }

    const isDisabled =
      !urlState.text || !urlState.url || urlAddingMode === 'loading'

    const config = useConfig()
    if (!config) {
      return null
    }

    const { C4C_BASE_URL, C4C_TICKET_ID_PARAM, C4C_OPTIONAL_PARAM } = config

    const handleSaveClick = async () => {
      try {
        if (c4cUrlAddingMode && caseId) {
          if (userID && ticketID && urlDescription) {
            const c4cBaseUrl = C4C_BASE_URL
            const c4cTicketIdParam = C4C_TICKET_ID_PARAM
            const c4cOptionalParam = C4C_OPTIONAL_PARAM
            const subdomain = `my${userID}`
            const c4cTargetUrl: string = `https://${subdomain}.${c4cBaseUrl}&${c4cTicketIdParam}=${ticketID}&${c4cOptionalParam}`
            await postUrl({ caseId, url: c4cTargetUrl, label: urlDescription })
            handleCloseDialog()
          } else {
            setError('Failed to add the url')
          }
        } else {
          if (!caseId || !urlState.url || !urlState.text) {
            return
          }
          const validUrl = validateUrl(urlState.url)
          if (!validUrl) {
            throw new Error('Provided url is invalid')
          }
          setUrlAddingMode('loading')
          await postUrl({ caseId, url: urlState.url, label: urlState.text })
          setUrlAddingMode(false)
          setUrlState({ url: '', text: '' })
        }
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message)
        } else {
          setError('Failed to add the url')
        }
      }
    }

    const handleFieldChange = (
      field: string,
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setUrlState({ ...urlState, [field]: event.target.value })
    }

    const handleEscapePress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setUrlAddingMode(false)
        setUrlState({ url: '', text: '' })
      }
    }

    const handleUrlRemoval = (id: string) => {
      // TODO check urlID
      if (caseId) removeUrl({ caseId, urlId: id })
    }

    const handleOpenDialog = () => {
      setShowDialog(true)
      setC4cUrlAddingMode(true)
    }

    const handleCloseDialog = () => {
      setShowDialog(false)
      setC4cUrlAddingMode(false)
    }

    return (
      <>
        {error && (
          <SnackbarError message={error} onClose={() => setError(null)} />
        )}
        <Grid container>
          {!urlAddingMode && (
            <Grid container item xs={6}>
              <Grid container item xs={3}>
                <Typography
                  variant='h6'
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '16px',
                    justifyContent: 'center'
                  }}
                >
                  URLs
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <IconButton
                  className=''
                  onClick={() => setUrlAddingMode(true)}
                  style={{
                    backgroundColor: 'white',
                    padding: 0,
                    marginBottom: 5
                  }}
                >
                  <AddCircleOutlineIcon
                    sx={{ color: '#0080f0', ...iconsProps }}
                  />
                </IconButton>
              </Grid>
            </Grid>
          )}
          <Grid container item xs={12}>
            <Grid container item xs={3}>
              <Typography
                variant='h6'
                sx={{
                  fontSize: '12px',
                  fontWeight: '600',
                  justifyContent: 'center'
                }}
              >
                Create C4C Urls
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                data-testid='create-c4c-url-button'
                className=''
                onClick={handleOpenDialog}
                style={{
                  backgroundColor: 'white',
                  padding: 0,
                  marginBottom: 10
                }}
              >
                <AddCircleOutlineIcon
                  sx={{ color: '#0080f0', ...iconsProps }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <ul
          className='case-details__urls-list'
          data-testid='url-list-component'
        >
          {urls && urls.length
            ? urls.map(({ url, label, id }) => {
                const removeLabel = label + '-remove-button'
                return (
                  <li key={id} data-testid={label}>
                    <IconButton
                      data-testid={removeLabel}
                      onClick={() => handleUrlRemoval(id)}
                      className=''
                      style={{
                        backgroundColor: 'white',
                        marginRight: 10,
                        padding: 0
                      }}
                    >
                      <RemoveCircleOutlineIcon
                        sx={{
                          color: '#0080f0',
                          fontSize: 14,
                          padding: 0
                        }}
                      />
                    </IconButton>
                    <a href={url} target='_blank' rel='noopener noreferrer'>
                      {label}
                    </a>
                  </li>
                )
              })
            : null}
        </ul>
        {urlAddingMode && (
          <form onSubmit={handleSaveClick}>
            <table className='urls-list__input-table'>
              <tbody>
                <tr>
                  <td className='urls-list__input-table-header'>Url:</td>
                  <td className='urls-list__input-td'>
                    <TextField
                      autoFocus
                      onKeyDown={handleEscapePress}
                      value={urlState.url}
                      onChange={(event) => handleFieldChange('url', event)}
                      rows={1}
                      margin='normal'
                      className='urls-list__input'
                      variant='outlined'
                      fullWidth
                      sx={{
                        margin: 0,
                        padding: 0,
                        backgroundColor: 'white',
                        '&.MuiOutlinedInput-root': {
                          backgroundColor: 'white'
                        }
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='urls-list__input-table-header'>Text:</td>
                  <td className='urls-list__input-td'>
                    <TextField
                      value={urlState.text}
                      onKeyDown={handleEscapePress}
                      onChange={(event) => handleFieldChange('text', event)}
                      rows={1}
                      margin='normal'
                      className='urls-list__input'
                      variant='outlined'
                      fullWidth
                      sx={{
                        margin: 0,
                        padding: 0,
                        backgroundColor: 'white',
                        '&.MuiOutlinedInput-root': {
                          backgroundColor: 'white'
                        }
                      }}
                    />
                    <div>
                      <FormButton
                        type='submit'
                        disabled={isDisabled}
                        sx={{ mr: 1, p: 0 }}
                        variant='accept'
                      />
                      <FormButton
                        disabled={urlAddingMode === 'loading'}
                        sx={{ mr: 1, p: 0 }}
                        variant='decline'
                        onClick={() => handleCancelClick()}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        )}
        <IMDialog
          title='Create C4C Url'
          show={showDialog}
          onClose={handleCloseDialog}
          actions={{
            onOk: handleSaveClick,
            onClose: handleCloseDialog,
            okButtonVariant: 'contained',
            onOkButtonTitle: 'Confirm',
            onCloseButtonTitle: 'Close'
          }}
          fullWidth={true}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} data-testid='c4c-userid-textfield'>
              <UrlsListTextField
                title='UserID:'
                value={userID}
                onChange={(event) => setUserID(event.target.value)}
                helperTextValue='UserID'
              ></UrlsListTextField>
            </Grid>
            <Grid item xs={12} data-testid='c4c-ticketid-textfield'>
              <UrlsListTextField
                title='TicketID:'
                value={ticketID}
                onChange={(event) => setTicketID(event.target.value)}
                helperTextValue='TicketID'
              ></UrlsListTextField>
            </Grid>
            <Grid item xs={12} data-testid='c4c-description-textfield'>
              <UrlsListTextField
                title='Description:'
                value={urlDescription}
                onChange={(event) => setUrlDescription(event.target.value)}
                helperTextValue='Description'
              ></UrlsListTextField>
            </Grid>
          </Grid>
        </IMDialog>
      </>
    )
  }
)
