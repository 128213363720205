import * as React from 'react'
import { DialogTitle, Dialog, Typography, Grid } from '@mui/material'
import { IMDialogContent } from './dialogContent'
import { DialogActionsProps, IMDialogActions } from './dialogActions'

type DialogProps = React.PropsWithChildren<{
  title: string
  show: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  fullWidth?: boolean
  onClose?: () => void
  body?: { renderText?: string }
  actions: DialogActionsProps<null>
}>

export const IMDialog = (props: DialogProps) => {
  const { title, show, maxWidth, fullWidth, onClose, body, children, actions } =
    props
  return (
    <Grid container>
      <Dialog
        data-testid='im-dialog'
        open={show}
        fullWidth={fullWidth}
        maxWidth={maxWidth ? maxWidth : 'lg'}
        onClose={onClose}
      >
        {title && (
          <DialogTitle>
            <Typography variant={'h6'} align='center'>
              {title}
            </Typography>
          </DialogTitle>
        )}
        <IMDialogContent {...body} children={children} />
        <IMDialogActions {...actions} />
      </Dialog>
    </Grid>
  )
}
