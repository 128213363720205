import ModeEditIcon from '@mui/icons-material/ModeEdit'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { SnackbarError } from '../CaseDetails/snackbarError'
import { useCaseContext } from '../Contexts'
import { User } from '../Contexts/types'
import { DebugBorder } from '../utils/debugBorder'

export const Assignee = React.memo(
  ({
     assignee,
     assignable,
     assigneeHandler
   }: {
    assignee?: string
    assignable?: boolean
    assigneeHandler?: (val?: string) => Promise<void>
  }) => {
    const { fetchAssignableUsers } = useCaseContext()
    const [editMode, setEditMode] = useState(false)
    const [selectedUser, setSelectedUser] = useState('')
    const [error, setError] = useState('')
    const [usersList, setUsersList] = useState<User[]>([])
    const location = useLocation()
    const isRoot = location.pathname === '/'
    const [isLoading, setIsLoading] = useState(false)

    const handleEditAssignee = async () => {
      setEditMode(true)
      setIsLoading(true)
      try {
        const users = await fetchAssignableUsers()
        if (users?.length > 0) setUsersList(users)
      } catch (error) {
        setError('Error while fetching assignable users')
      } finally {
        setIsLoading(false)
      }
    }

    const handleAssigneeChange = async (event: SelectChangeEvent<string>) => {
      if (assigneeHandler) {
        setSelectedUser(event.target.value)
        try {
          setIsLoading(true)

          let selectedUserEmail: string | undefined = event.target.value
          if (!selectedUserEmail) {
            selectedUserEmail = undefined
          }
          await assigneeHandler(selectedUserEmail)
        } catch (error) {
          setError('Failed to change assigned user')
        } finally {
          setIsLoading(false)
        }
        setEditMode(false)
      }
    }

    const placeholderText = assignee ? 'Assigned user' : 'Select new user'

    return (
      <DebugBorder>
        {error && (
          <SnackbarError message={error} onClose={() => setError('')} />
        )}
        <div>
          {!editMode && (
            <span style={{ color: 'black' }}>
              {assignee || (isRoot ? '' : 'No user assigned')}
            </span>
          )}

          {assignable && !editMode && (
            <IconButton
              className='case-details__absolute-item'
              onClick={handleEditAssignee}
              sx={{ padding: 0, top: 0 }}
            >
              <ModeEditIcon sx={{ color: '#0080f0', width: 15, eight: 15 }} />
            </IconButton>
          )}

          {assignable && editMode && usersList.length > 0 && (
            <FormControl fullWidth>
              <InputLabel size='small' id='assigned-user-label'>
                {placeholderText}
              </InputLabel>
              <Select
                disabled={isLoading}
                fullWidth
                size='small'
                value={selectedUser || assignee || ''}
                onChange={handleAssigneeChange}
                onClose={(e) => {
                  if ((e.target as HTMLElement).role !== 'option') {
                    setEditMode(false)
                  }
                }}
                label={placeholderText}
                className='assignee__select'
              >
                {usersList.map(({ email }, index) => (
                  <MenuItem key={`${email}--${index}`} value={email}>
                    {email || 'Unassigned'}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
      </DebugBorder>
    )
  }
)
