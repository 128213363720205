import React from 'react'

const ADD_DEBUG_BORDER = false

type DebugBorderProps = {
  children?: React.ReactNode
}

/**
 * When you wrap this component around your components and set the
 * const var ADD_DEBUG_BORDER to TRUE then a border will be rendered.
 * This behaviour can be helpful when a developer is defining a layout
 * for a page or component.
 *
 * @example
 * <DebugBorder>
 *   <YourComponent/>
 * </DebugBorder>
 *
 * @author Andreas Küster
 * @param {DebugBorderProps} props
 * @constructor
 */
export const DebugBorder: React.FC<DebugBorderProps> = (props) => {
  if (ADD_DEBUG_BORDER) {
    return <div style={{ border: '1px solid black' }}>{props.children}</div>
  }

  return <div>{props.children}</div>
}
