import React from 'react'
// import ksb from './ksb.png';
import { QueryClient, QueryClientProvider } from 'react-query'
import { Amplify } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'
import { CaseProvider } from '../Contexts'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { CaseDetails } from '../CaseDetails'
import { CasesOverview } from '../CasesOverview'
import { Layout } from '../Layout'

import './app.sass'
import { AuthProvider } from '../Contexts/AuthContext'
import { useConfig } from '../hooks/useConfig'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { CasesProvider } from '../Contexts/CasesContext'

if (process.env.NODE_ENV !== 'test') {
  require('@aws-amplify/ui-react/styles.css')
}

export function App() {
  const config = useConfig()
  if (!config) {
    return null
  }

  const { CLIENT_ID, USER_POOL_ID } = config

  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolClientId: CLIENT_ID,
        userPoolId: USER_POOL_ID
      }
    }
  })

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        enabled: !!config
      }
    }
  })

  return (
    <div className='App'>
      <header className='App-header' data-testid='login-authenticator'>
        <Authenticator hideSignUp={true}>
          {({ signOut, user }) => (
            <>
              {user ? (
                <AuthProvider>
                  <Router>
                    <QueryClientProvider client={queryClient}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Layout signOut={signOut!} user={user}>
                          <Routes>
                            <Route
                              path='/'
                              element={
                                <CasesProvider config={config}>
                                  <CasesOverview />
                                </CasesProvider>
                              }
                            />
                            <Route
                              path='/cases/:caseId'
                              element={
                                <CaseProvider config={config}>
                                  <CaseDetails />
                                </CaseProvider>
                              }
                            />
                          </Routes>
                        </Layout>
                      </LocalizationProvider>
                    </QueryClientProvider>
                  </Router>
                </AuthProvider>
              ) : null}
            </>
          )}
        </Authenticator>
      </header>
    </div>
  )
}
