import React, { FC } from 'react'
import { Failure } from '../../../../../Contexts/types'
import { getFailureStateBorderColor } from '../../../../../utils/getFailureStateColor'
import {
  formatFailureState,
  formatFailureType
} from '../../../../../utils/formatFailureType'
import { Grid, Tooltip } from '@mui/material'
import './alarmCardHeader.sass'

interface AlarmCardHeaderProps {
  failure: Failure
}

export const AlarmCardHeader: FC<AlarmCardHeaderProps> = ({ failure }) => {
  const title = formatFailureType(failure.failureType)
  const borderColor = getFailureStateBorderColor(failure.failureState)
  const status = formatFailureState(failure.failureState)

  return (
    <div className='alarm-card-header'>
      <Grid container>
        <Grid item xs={9}>
          <Tooltip title={title}>
            <div className='alarm-card-header-title'>{title}</div>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <div
            className='alarm-card-header-status'
            style={{ backgroundColor: borderColor }}
          >
            {status}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
