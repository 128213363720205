import React from 'react'
import { TextField, IconButton, InputAdornment } from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'
import { useCasesContext } from '../Contexts/CasesContext'

export const SearchBar = () => {
  const { handleSearch, searchTerm } = useCasesContext()
  const handleChange = (event: React.SyntheticEvent) => {
    const value = (event.target as HTMLInputElement).value

    handleSearch(value)
  }

  return (
    <TextField
      fullWidth
      placeholder='Pump Name, Organization, etc...'
      value={searchTerm}
      onChange={handleChange}
      sx={{ padding: '10px 0' }}
      data-testid='search-bar-text-field'
      InputProps={{
        style: { padding: 0 },
        endAdornment: (
          <InputAdornment position='start' sx={{ padding: '10px 0' }}>
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}
