import dayjs from 'dayjs'
import { Failure } from '../Contexts/types'
import { ColumnFilter } from './table-props/table-classes/columnFilter'
import { MRT_Cell, MRT_ColumnDef } from 'material-react-table'
import { CaseData } from './table-props/table-classes/caseData'
import {
  formatFailureAccessorKey,
  formatFailureState,
  formatFailureType
} from '../utils/formatFailureType'
import React from 'react'
import { formatDateToUtcString } from '../utils/formatDateString'
import { getFailureStateBackgroundColor } from '../utils/getFailureStateColor'

const alarmStateSortOrder = [
  'FAILURESTATE_ALARM',
  'FAILURESTATE_WARN',
  'FAILURESTATE_NORM',
  'FAILURESTATE_OKAY'
]

export const tagFilterFunction = (row: any, id: string, filterValue: any) => {
  const cellTags = row.getValue(id) as string[]
  const searchedTags = filterValue.split(',')
  for (const tag of searchedTags) {
    if (
      !cellTags.some((cellTag) =>
        cellTag.toLowerCase().includes(tag.toLowerCase().trim())
      )
    ) {
      return false
    }
  }
  return true
}

export const excludeTagFilterFunction = (
  row: any,
  id: string,
  filterValue: any
) => {
  const cellTags = row.getValue(id) as string[]
  const searchedTags = filterValue.split(',')
  for (const tag of searchedTags) {
    if (
      cellTags.some((cellTag) => {
        const lowerCellTag = cellTag.toLowerCase()
        const lowerTag = tag.toLowerCase().trim()
        return lowerCellTag.includes(lowerTag.trim())
      })
    ) {
      return false
    }
  }
  return true
}

export const alarmFilterFunction = (row: any, id: string, filterValue: any) => {
  const cellAlarms = row.getValue(id) as Failure[]
  if (!cellAlarms || cellAlarms.length === 0) {
    return false
  }
  const searchedAlarms = filterValue.split(',') as string[]
  for (const searchedAlarm of searchedAlarms) {
    const searchedAlarmLower = searchedAlarm.toLowerCase()

    return cellAlarms.some(
      (cellAlarm) =>
        cellAlarm.failureType.toLowerCase().includes(searchedAlarmLower) ||
        cellAlarm.failureState.toLowerCase().includes(searchedAlarmLower)
    )
  }
  return false
}

export const alarmSortingFunction = (
  oneRow: any,
  otherRow: any,
  columnId: string
) => {
  const oneRowFailures = oneRow.getValue(columnId) as Failure[] | undefined
  const otherRowFailures = otherRow.getValue(columnId) as Failure[] | undefined

  if (
    (!oneRowFailures && !otherRowFailures) ||
    (oneRowFailures && !otherRowFailures)
  ) {
    return 1
  }

  if (!oneRowFailures && otherRowFailures) {
    return -1
  }

  const hasOneRowAlarms = oneRowFailures!.some((x) =>
    x.failureState.toLowerCase().includes('alarm')
  )
  const hasOtherRowAlarms = otherRowFailures!.some((x) =>
    x.failureState.toLowerCase().includes('alarm')
  )
  const hasOneRowWarns = oneRowFailures!.some((x) =>
    x.failureState.toLowerCase().includes('warn')
  )
  const hasOtherRowWarns = otherRowFailures!.some((x) =>
    x.failureState.toLowerCase().includes('warn')
  )

  if (
    (hasOneRowAlarms && hasOtherRowAlarms) ||
    (hasOneRowAlarms && !hasOtherRowAlarms) ||
    (hasOneRowWarns && hasOtherRowWarns) ||
    (hasOneRowWarns && !hasOtherRowWarns)
  ) {
    return 1
  }

  if (
    (!hasOneRowAlarms && hasOtherRowAlarms) ||
    (!hasOneRowWarns && hasOtherRowWarns)
  ) {
    return -1
  }

  return 1
}

export const updateDateFiltersFunction = (filter: ColumnFilter) => {
  if (
    filter?.id === 'firstAlarmOccurrence' ||
    filter?.id === 'lastAlarmOccurrence'
  ) {
    const newValues = [
      (filter.value as string[])[0] ? dayjs((filter.value as string[])[0]) : '',
      (filter.value as string[])[1] ? dayjs((filter.value as string[])[1]) : ''
    ]
    return { id: filter.id, value: newValues }
  } else if (filter?.id.startsWith('firstOccurrence_')) {
    const eventType = filter.id.substring('firstOccurrence_'.length)
    const betweenInclusiveTypes = [
      'vibration',
      'temperaturehigh',
      'nodata',
      'xyz',
      'other'
    ]
    if (betweenInclusiveTypes.includes(eventType)) {
      const newValues = [
        (filter.value as string[])[0]
          ? dayjs((filter.value as string[])[0])
          : '',
        (filter.value as string[])[1]
          ? dayjs((filter.value as string[])[1])
          : ''
      ]

      return { id: filter.id, value: newValues }
    }
  } else if (filter?.id.startsWith('lastOccurrence_')) {
    const eventType = filter.id.substring('lastOccurrence_'.length)
    const betweenInclusiveTypes = [
      'vibration',
      'temperaturehigh',
      'nodata',
      'xyz',
      'other'
    ]
    if (betweenInclusiveTypes.includes(eventType)) {
      const newValues = [
        (filter.value as string[])[0]
          ? dayjs((filter.value as string[])[0])
          : '',
        (filter.value as string[])[1]
          ? dayjs((filter.value as string[])[1])
          : ''
      ]
      return { id: filter.id, value: newValues }
    }
  }
  return filter
}

export const createColumnsForFirstOccurrenceByFailureType = (
  failureTypes: string[]
): MRT_ColumnDef<CaseData>[] => {
  return failureTypes.map((failureType) => {
    const accessorKey = `firstOccurrence_${failureType.replaceAll('_', '').toLowerCase()}`

    return {
      accessorFn: (row: CaseData) => {
        const dateValue = row[accessorKey]

        if (!dateValue) {
          return ''
        }

        return new Date(dateValue)
      },
      header: `First Occurrence (${formatFailureType(failureType)})`,
      accessorKey: accessorKey,
      filterVariant: 'datetime',
      filterFn: 'betweenInclusive',
      sortingFn: 'datetime',

      Cell: ({ cell }: { cell: MRT_Cell<CaseData> }) => {
        const dateString = handleEmptyDateFunction(cell)
        return <div>{dateString}</div>
      }
    }
  })
}
export const createColumnsForLastOccurrenceByFailureType = (
  failureTypes: string[]
): MRT_ColumnDef<CaseData>[] => {
  return failureTypes.map((failureType) => {
    const accessorKey = `lastOccurrence_${failureType.replaceAll('_', '').toLowerCase()}`
    return {
      accessorFn: (row: CaseData) => {
        const dateValue = row[accessorKey]

        if (!dateValue) {
          return ''
        }

        return new Date(dateValue)
      },
      header: `Last Occurrence (${formatFailureType(failureType)})`,
      accessorKey: accessorKey,
      filterVariant: 'datetime',
      filterFn: 'betweenInclusive',
      sortingFn: 'datetime',
      Cell: ({ cell }: { cell: MRT_Cell<CaseData> }) => {
        const dateString = handleEmptyDateFunction(cell)
        return <div>{dateString}</div>
      }
    }
  })
}

export const createColumnsForAlarmByFailureType = (
  distinctFailureTypes: string[]
): MRT_ColumnDef<CaseData>[] => {
  return distinctFailureTypes.map((failureType) => {
    return {
      header: `Alarm (${formatFailureType(failureType)})`,
      accessorKey: formatFailureAccessorKey(failureType),
      Cell: ({ cell }: { cell: MRT_Cell<CaseData> }) => {
        const failure = cell.getValue() as Failure
        if (failure === undefined) {
          return null
        } else {
          return (
            <>
              <div
                className='table-entry-failure-status'
                style={{
                  backgroundColor: `${getFailureStateBackgroundColor(failure.failureState)}`
                }}
              >
                {formatFailureState(failure.failureState)}
              </div>
            </>
          )
        }
      },
      filterFn: 'alarmFilter',
      sortingFn: (rowA, rowB, columnId) => {
        const failureA = rowA.getValue<Failure>(columnId)
        const failureB = rowB.getValue<Failure>(columnId)

        const stateA = failureA?.failureState ?? ''
        const stateB = failureB?.failureState ?? ''

        return (
          alarmStateSortOrder.indexOf(stateA) -
          alarmStateSortOrder.indexOf(stateB)
        )
      }
    }
  })
}

export const handleEmptyDateFunction = (cell: MRT_Cell<CaseData>): string => {
  const date = cell.getValue<Date>()
  return date ? formatDateToUtcString(date) : ''
}
