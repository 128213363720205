import { useState } from 'react'
import { UpdatedFailureAcknowledgementData } from '../../../../../../Contexts/types'
import { AcknowledgementSwitchProps } from './acknowledgmentSwitch'

export interface UseAcknowledgementSwitchHookFunctions {
  isAcknowledged: boolean
  handleChangeToggle: () => void
}

export const useAcknowledgementSwitchHook = (
  props: AcknowledgementSwitchProps
): UseAcknowledgementSwitchHookFunctions => {
  const [isAcknowledged, setIsAcknowledged] = useState<boolean>(
    props.failure.acknowledged
  )

  const handleChangeToggle = () => {
    setIsAcknowledged((prevIsAcknowledged) => !prevIsAcknowledged)
    // TODO(burak): add update acknowledgement logic
    const acknowledgement: UpdatedFailureAcknowledgementData = {
      acknowledged: !isAcknowledged,
      caseId: props.caseId,
      failureType: props.failure.failureType
    }
    props.updateFailureAcknowledgement(acknowledgement)
    console.log('Acknowledgement status: ', acknowledgement)
  }

  return {
    isAcknowledged,
    handleChangeToggle
  }
}
